(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom"), require("react"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["react-dom", "react", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["Plots"] = factory(require("react-dom"), require("react"), require("lodash"));
	else
		root["Plots"] = factory(root["ReactDOM"], root["React"], root["_"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__442__, __WEBPACK_EXTERNAL_MODULE__276__) => {
return 